<script>
  import { router } from '../router'
  import { Icon } from 'svelma-fixed'
  import markets from '../stores/markets'
  import ErrorBox from '../components/ErrorBox.svelte'
  import { formatPercentage } from '../lib/utils'

  let collateralCoins
  $: {
    if ($markets) {
      collateralCoins = Object.values($markets.coins).filter(coin => !coin.hidden && coin.collateralActive)

      // Merge ETH/WETH in this list
      if (collateralCoins.includes($markets.coins.ETH) && collateralCoins.includes($markets.coins.WETH)) {
        collateralCoins = collateralCoins.filter(coin => coin.symbol !== 'WETH')
        const ethIndex = collateralCoins.findIndex(coin => coin.symbol === 'ETH')
        collateralCoins[ethIndex] = {
          ...$markets.coins.ETH,
          symbol: 'ETH / WETH',
          name: 'Ether / Wrapped Ether',
          services: {
            ...$markets.coins.ETH.services,
            ...$markets.coins.WETH.services
          }
        }
      }
    }
  }
</script>

<svelte:head>
  <title>Borrow Limits - WEHODL</title>
</svelte:head>

<style lang="scss">
  @import "bulma/sass/utilities/mixins.sass";

  .image img {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }

  th, td {
    min-height: 50px;
    vertical-align: middle;

    &:first-child, &:nth-child(2) {
      @include touch {
        display: none;
      }
    }
  }
</style>

<div class="container py-3">
  {#if window.opener}
    <a href={$router.routeURL({ name: 'calculator' })} on:click={() => window.close()}><Icon icon="arrow-left" /> Back to Calculator</a>
  {/if}

  <h1 class="title mt-3">
    DeFi Borrow Limit
  </h1>

  <p class="content">
    The Borrow Limit, also known as <strong>Loan-To-Value (LTV)</strong> ratio, defines the maximum amount of currency that can be borrowed with a specific collateral. It’s expressed in percentage: at LTV=75%, for every 1 ETH worth of collateral, borrowers will be able to borrow 0.75 ETH worth of the corresponding currency. Once a borrow is taken, the LTV evolves with market conditions.
  </p>

  {#if collateralCoins}
    <table class="table is-fullwidth">
      <thead>
        <tr class="sticky-header">
          <th></th>
          <th>Coin</th>
          <th>Symbol</th>
          <th>Compound V2 LTV</th>
          <th>Compound V3 USDC LTV</th>
          <th>Aave V2 LTV</th>
        </tr>
      </thead>
      <tbody>
        {#each collateralCoins as coin}
          <tr>
            <td><figure class="image"><img src="{coin.iconUrl}" alt="{coin.symbol}"></figure></td>
            <td>{coin.name}</td>
            <td>{coin.symbol}</td>
            <td>{formatPercentage(coin.services.compoundV2?.borrowLimit || null, undefined, undefined, '-')}</td>
            <td>{formatPercentage(coin.services.compoundV3_USDC?.borrowLimit || null, undefined, undefined, '-')}</td>
            <td>{formatPercentage(coin.services.aaveV2?.borrowLimit || null, undefined, undefined, '-')}</td>
          </tr>
        {/each}
      </tbody>
    </table>
  {:else}
    <ErrorBox>There is currently no market data available - please try again later!</ErrorBox>
  {/if}
</div>
