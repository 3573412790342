<svelte:options accessors />

<script>
  import { Button, Icon, Tooltip, Snackbar, Dialog } from 'svelma-fixed'
  import { sortAssets } from '../lib/liquidationCalculator'
  import { formatCurrency } from '../lib/utils'
  import { calculatorState, setDirty } from '../stores/calculatorState'
  import { quoteCoin } from '../stores/quoteCoin'
  import { mode } from '../stores/walletManager'
  import ValueDisplay from './ValueDisplay.svelte'
  import { ga } from '@beyonk/svelte-google-analytics'
  import services from '../lib/services'
  import InfoTooltip from './InfoTooltip.svelte'
  import { createEventDispatcher } from 'svelte'
  import { fly } from 'svelte/transition'
  import { flip } from 'svelte/animate'
  import UpdateAssetDialog from './UpdateAssetDialog.svelte'
  import dialogs from '../stores/dialogs'
  import AssetCard from './AssetCard.svelte'
  import AddAssetDialog from './AddAssetDialog.svelte'
  import WalletActions from './WalletActions.svelte'
  import markets from '../stores/markets'

  const dispatch = createEventDispatcher()

  export let type = 'collateral'
  export let service = Object.keys(services)[0]
  export let assets = []
  export let augmentedAssets = []
  export let fullState
  export let coinItems = []
  export let borrowLimitReached = false

  $: quoteCoinEthPrice = $calculatorState.quoteCoinPrices[$quoteCoin.symbol] ?? $quoteCoin.ethPrice

  $: liveWallet = $mode !== 'readOnly'

  $: {
    const sortedAssets = sortAssets(augmentedAssets)
    if (sortedAssets.map(a => a.symbol).join() !== assets.map(a => a.symbol).join()) {
      augmentedAssets = sortedAssets

      assets = augmentedAssets.map(({ symbol, units, enabled }) => ({ symbol, units, enabled }))
    }
  }

  let walletActions

  const getAsset = (symbol, augmented = false) => {
    const asset = (augmented ? augmentedAssets : assets).find(a => a.symbol === symbol)
    if (!asset) throw new Error('Asset not found')
    return asset
  }

  function triggerChangeEvent () {
    setDirty(service)
    dispatch('change')
  }

  function checkBorrowLimit () {
    if (borrowLimitReached) {
      Dialog.alert({
        message: 'You have reached your borrowing limit. Please repay some of your debt first.',
        type: 'is-danger',
        icon: 'exclamation-circle'
      })
      return false
    }
    return true
  }

  function applyQuotePriceChange (symbol, quotePrice) {
    const originalPrice = $markets.coins[symbol].services[service].price

    for (const [pricingService, values] of Object.entries($calculatorState.assetPrices)) {
      if (pricingService === service || symbol in values) {
        values[symbol] = quotePrice != null ? quotePrice / quoteCoinEthPrice : originalPrice
      }
    }

    $calculatorState.assetPrices = $calculatorState.assetPrices
    $calculatorState.modifiedAssetPrices[symbol] = quotePrice != null

    if (quotePrice != null && $calculatorState.autoMarketRefresh) {
      $calculatorState.autoMarketRefresh = false

      Snackbar.create({ message: '<strong>Auto market refresh has been disabled</strong> due to custom price being entered.<br>You can re-enable it with the switch in the top left.', type: 'is-success', duration: 10000 })
    }
  }

  export async function add () {
    if (liveWallet) {
      if (type === 'collateral') {
        const augmentedAsset = await dialogs.open(AddAssetDialog, {
          service,
          type,
          coinItems,
          onlyWithBalance: true,
          title: 'Deposit'
        })

        if (service === 'compoundV3_USDC' && augmentedAsset?.symbol === 'USDC' && fullState.assets.borrow.some(a => a.symbol === 'USDC')) {
          Dialog.alert({
            message: 'You already borrowed USDC. If you want to supply USDC, please repay your borrowed USDC first.',
            type: 'is-danger',
            icon: 'exclamation-circle'
          })
          return
        }

        if (augmentedAsset) walletActions.deposit(augmentedAsset.symbol, true)
      } else {
        if (!$calculatorState.services[service]?.assets.collateral.some(a => a.enabled && a.units > 0)) {
          Dialog.alert({
            message: 'You don\'t have any active collateral assets, so you cannot borrow. Please add some collateral first.',
            type: 'is-danger',
            icon: 'exclamation-circle'
          })
          return
        }

        if (!checkBorrowLimit()) return

        const augmentedAsset = await dialogs.open(AddAssetDialog, {
          service,
          type,
          coinItems,
          title: 'Borrow'
        })

        if (service === 'compoundV3_USDC' && augmentedAsset?.symbol === 'USDC' && fullState.assets.collateral.some(a => a.symbol === 'USDC')) {
          Dialog.alert({
            message: 'You already supplied USDC. If you want to borrow USDC, please withdraw your supplied USDC first.',
            type: 'is-danger',
            icon: 'exclamation-circle'
          })
          return
        }

        if (augmentedAsset) walletActions.borrow(augmentedAsset.symbol, true)
      }
    } else {
      const augmentedAsset = await dialogs.open(AddAssetDialog, {
        service,
        type,
        coinItems
      })

      if (augmentedAsset) {
        if (type === 'collateral') {
          if (service === 'compoundV3_USDC' && augmentedAsset?.symbol === 'USDC' && fullState.assets.borrow.some(a => a.symbol === 'USDC')) {
            Dialog.alert({
              message: 'You already borrowed USDC. If you want to supply USDC, please repay your borrowed USDC first.',
              type: 'is-danger',
              icon: 'exclamation-circle'
            })
            return
          }
        } else {
          if (service === 'compoundV3_USDC' && augmentedAsset?.symbol === 'USDC' && fullState.assets.collateral.some(a => a.symbol === 'USDC')) {
            Dialog.alert({
              message: 'You already supplied USDC. If you want to borrow USDC, please withdraw your supplied USDC first.',
              type: 'is-danger',
              icon: 'exclamation-circle'
            })
            return
          }
        }

        if (fullState.assets[type].some(a => a.symbol === augmentedAsset.symbol)) {
          return await edit(augmentedAsset.symbol)
        }

        const update = await dialogs.open(UpdateAssetDialog, {
          service,
          type,
          allowZero: false,
          augmentedAsset,
          empty: true,
          hasQuantity: true,
          hasPrice: true,
          originalQuotePrice: $markets.coins[augmentedAsset.symbol].services[service].price * quoteCoinEthPrice
        })

        if (update) {
          assets = [...assets, { symbol: augmentedAsset.symbol, units: update.units, enabled: type === 'collateral' ? augmentedAsset.serviceData.collateralForcedEnableState ?? true : true }]

          applyQuotePriceChange(augmentedAsset.symbol, update.quotePrice)

          triggerChangeEvent()

          ga.addEvent('add_asset', { service, symbol: augmentedAsset.symbol })
        }
      }
    }
  }

  export async function edit (symbol) {
    ga.all.selectContent(`${type}_asset`, symbol)

    if (liveWallet) {
      if (type === 'collateral') {
        walletActions.deposit(symbol)
      } else {
        walletActions.borrow(symbol)
      }
    } else {
      const asset = getAsset(symbol)
      const augmentedAsset = getAsset(symbol, true)

      const update = await dialogs.open(UpdateAssetDialog, {
        service,
        type,
        allowZero: true,
        augmentedAsset,
        hasQuantity: true,
        hasPrice: true,
        originalQuotePrice: $markets.coins[augmentedAsset.symbol].services[service].price * quoteCoinEthPrice
      })

      if (update) {
        if (!update.units) {
          return await remove(symbol)
        }

        asset.units = update.units
        assets = assets

        applyQuotePriceChange(symbol, update.quotePrice)

        triggerChangeEvent()

        ga.addEvent('edit_asset', { service, symbol })
      }
    }
  }

  export async function remove (symbol) {
    if (liveWallet) {
      throw new Error('Invalid operation')
    } else {
      assets = assets.filter(asset => asset.symbol !== symbol)

      triggerChangeEvent()

      ga.addEvent('remove_asset', { service, symbol })
    }
  }

  function onEnabledChange (symbol, value) {
    if (liveWallet) {
      throw new Error('Invalid operation')
    } else {
      const asset = assets.find(a => a.symbol === symbol)
      if (!asset) throw new Error('Asset not found')
      asset.enabled = value
      assets = assets

      triggerChangeEvent()
    }
  }
</script>

<style lang="scss">
  @import "bulma/sass/utilities/mixins.sass";

  .asset-div:last-child :global(.card) {
    margin-bottom: 0 !important;
  }

  .service-disabled {
    filter: blur(6px);
    pointer-events: none;
    opacity: 0.6;
  }

  .card-content {
    position: relative;
    padding: 0.5rem;
  }

  h5 {
    font-weight: normal;
  }

  .add-button {
    position: absolute;
    top: 0.2em;
    right: 0.2em;

    :global(.button) {
      border-radius: $radius;
    }

    :global(.icon) {
      font-size: 1.5rem;
    }
  }

  aside {
    font-size: 0.8rem;
    color: $grey;
  }
</style>

<main class:service-disabled={services[service]?.disabled}>
  <div class="card has-text-centered mb-5">
    <div class="card-content">
      <span style:display="contents" class="info-tooltip-area">
        <h5 class="title is-5 mb-3">
          {#if type === 'collateral'}
            Collateral
            <InfoTooltip>
              Collateral value decreases when market decreases. Always leave some funds aside in your wallet in case more collateral is needed.
            </InfoTooltip>
          {:else if type === 'borrow'}
            Borrow
            <InfoTooltip>
              You can borrow up to a certain percantage of your collateral balance, depending on the assets used.
            </InfoTooltip>
          {:else}
            Assets
          {/if}
        </h5>
        <div class="add-button">
          <Tooltip position="is-left" label="Add Asset">
            <Button size="is-small" outlined on:click={() => add()}><Icon icon="plus-square" pack="far" /></Button>
          </Tooltip>
        </div>

        <h3 class="title is-3 mb-3">
          <ValueDisplay --font-size="100%" value={formatCurrency(augmentedAssets.filter(asset => asset.enabled).reduce((acc, asset) => acc + asset.quoteValue, 0), $quoteCoin.symbol, undefined, undefined, true)} />
        </h3>
      </span>

      {#if type === 'collateral'}
        <aside class="info-tooltip-area">
          Liquidation Threshold: {formatCurrency(fullState.calcValues.thresholdCollateralValue, $quoteCoin.symbol, undefined, 'N/A', true)}
          <InfoTooltip>
            <p>If your Collateral Balance reaches this value, your assets will be liquidated.</p>
          </InfoTooltip>
        </aside>
      {:else if type === 'borrow'}
        <aside class="info-tooltip-area">
          Borrow Limit: {formatCurrency(fullState.calcValues.borrowLimit, $quoteCoin.symbol, undefined, 'N/A', true)}
          <InfoTooltip>
            <p>Maximum amount allowed to borrow. Borrowing more than the maximum amount might lead to liquidation of the collateral.</p>
          </InfoTooltip>
        </aside>
      {/if}
    </div>
  </div>

  {#each augmentedAssets as augmentedAsset (augmentedAsset.symbol)}
    <div
      class="asset-div"
      transition:fly|local={{ duration: 200, y: -10 }}
      animate:flip={{ duration: d => 30 * Math.sqrt(d) }}
    >
      <AssetCard
        {augmentedAsset}
        {service}
        {type}
        {quoteCoinEthPrice}
        showAmounts
        showRemove={!liveWallet}
        showEnable={!liveWallet && type === 'collateral'}
        on:click={() => edit(augmentedAsset.symbol)}
        on:enable={e => onEnabledChange(augmentedAsset.symbol, e.detail)}
        on:remove={() => remove(augmentedAsset.symbol)}
      />
    </div>
  {/each}
</main>

<WalletActions bind:this={walletActions} />
