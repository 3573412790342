<script>
  import { registeredWallets, renameAddress, updatingLiveData, updateLiveData, unlinkConnectedWallet, unlinkImportedWallet, connectedWalletAddress, importedWalletAddress } from '../stores/walletManager'
  import { getHumanizedDuration } from '../lib/utils'
  import { createEventDispatcher } from 'svelte'
  import markets from '../stores/markets'
  import { Tab, Button, Icon } from 'svelma-fixed'
  import TabsBox from './TabsBox.svelte'
  import Tooltip from './Tooltip.svelte'
  import ModalDialog from './ModalDialog.svelte'
  import AutoUpdate from './AutoUpdate.svelte'
  import BalancesList from './BalancesList.svelte'
  import HistoryList from './HistoryList.svelte'
  import uri from 'uri-tag'

  const dispatch = createEventDispatcher()

  export let address
  $: wallet = $registeredWallets[address]

  let assetSearchValue = ''
  let transactionsSearchValue = ''

  $: sortedBalances = [...wallet?.liveData?.balances ?? []].sort((a, b) => b.ethValue - a.ethValue || a.symbol.localeCompare(b.symbol))

  $: unusedSupplyAssets = Object.entries(wallet?.liveData?.services)
    .flatMap(([service, { unusedSupplyAssets = [] }]) => unusedSupplyAssets.map(asset => ({ service, ...asset })))
    .map(asset => ({ ...asset, ethValue: asset.units * $markets.coins[asset.symbol]?.price ?? 0 }))
    .sort((a, b) => b.ethValue - a.ethValue || a.symbol.localeCompare(b.symbol) || a.service.localeCompare(b.service))

  $: history = [...wallet?.history ?? []].sort((a, b) => b.timestamp - a.timestamp)

  export let initialTabIndex
</script>

<style lang="scss">
  @import 'bulma/sass/utilities/mixins.sass';

  small.below-button {
    display: block;
    margin: 0.25rem 0;
    width: 100%;
  }

  .wallet-type-icon {
    width: 1.5em;
    height: 1.5em;
    margin: -0.25em 0;
    display: inline-block;
    vertical-align: middle;
  }

  code.address {
    word-break: break-word;

    @include touch {
      font-size: 80%;
    }
  }
</style>

<ModalDialog on:close title="Wallet Details" closeable --min-width="800px">
  {#if wallet}
    <div class="columns">
      <div class="column is-6-desktop">
        <strong>Address:</strong> <small>(<a href={uri`https://etherscan.io/address/${wallet.address}`} target="_blank" rel="noopener noreferrer">Show on Etherscan</a>)</small>
        <br />
        <code class="address">{wallet.address}</code>
      </div>
      <div class="column is-6-desktop">
        <strong>Name:</strong>
        <br />
        {#if wallet.name}
          {wallet.name}
        {:else}
          <span class="has-text-grey-light">(none)</span>
        {/if}
        &nbsp;
        <Tooltip label="Rename"><a href={undefined} on:click={() => renameAddress(wallet.address)}><Icon size="is-small" icon="pen" /></a></Tooltip>
      </div>
    </div>

    <div class="columns">
      <div class="column is-12">
        <TabsBox --max-scrollbox-height="max(150px, calc(100vh - 400px))" {initialTabIndex}>
          <Tab label="Balances" icon="money-bill">
            <BalancesList bind:search={assetSearchValue} assets={sortedBalances} showTotal="Total (only supported assets)">
              <svelte:fragment slot="footer">
                <Icon icon="info-circle" /> There may be additional assets not listed here! <a href={uri`https://etherscan.io/tokenholdings?a=${address}`} target="_blank">Check on Etherscan</a> for the full list.
              </svelte:fragment>
            </BalancesList>
          </Tab>
          <Tab label="Unused Supply" icon="power-off">
            <BalancesList bind:search={assetSearchValue} assets={unusedSupplyAssets} showTotal />
          </Tab>
          <Tab label="History" icon="history">
            <HistoryList {wallet} bind:search={transactionsSearchValue} transactions={history} />
          </Tab>
        </TabsBox>
      </div>
    </div>

    <div class="columns">
      <div class="column is-6-desktop">
        <Button outlined on:click={() => updateLiveData()} loading={$updatingLiveData} iconLeft="sync" class="is-fullwidth">Sync</Button>
        <small class="below-button">
          <AutoUpdate always>Updated {getHumanizedDuration(Date.now() - wallet.liveData?.lastUpdate)} ago</AutoUpdate>
        </small>
      </div>
      <div class="column is-6-desktop">
        {#if $connectedWalletAddress?.toLowerCase() === wallet.address.toLowerCase()}
          <Button outlined on:click={() => { unlinkConnectedWallet(); dispatch('close') }} disabled={$updatingLiveData} iconLeft="unlink" class="is-fullwidth">Disconnect</Button>
        {:else if $importedWalletAddress?.toLowerCase() === wallet.address.toLowerCase()}
          <Button outlined on:click={() => { unlinkImportedWallet(); dispatch('close') }} disabled={$updatingLiveData} iconLeft="power-off" class="is-fullwidth">Unlink</Button>
        {/if}
        <small class="below-button has-text-right-desktop">
          {#if $connectedWalletAddress?.toLowerCase() === wallet.address.toLowerCase()}
            Connected with <span class="wallet-type-icon">{@html wallet.walletTypeIconHtml ?? ''}</span> {wallet.walletTypeLabel ?? 'Wallet'}.
          {:else if $importedWalletAddress?.toLowerCase() === wallet.address.toLowerCase()}
            Read-only mode. No access to funds.
          {/if}
        </small>
      </div>
    </div>
  {:else}
    Wallet not found!
  {/if}
</ModalDialog>
