<script>
  import { fly, fade } from 'svelte/transition'
  import { Button, Icon, Switch, Toast } from 'svelma-fixed'
  import Tooltip from '../components/Tooltip.svelte'
  import services from '../lib/services'
  import { activeTabId, thisTabId, calculatorState, selectedService, currentCoreData, updateStateFromMarkets, updateStateFromLiveData, restoreRoState, saveRoState, clearRoState, autoMarketRefresh } from '../stores/calculatorState'
  import { activeWalletAddress, activeWallet, updateLiveData, mode, getWalletName, updatingLiveData, setImportedWallet, unlinkImportedWallet, unlinkConnectedWallet } from '../stores/walletManager'
  import markets from '../stores/markets'
  import { getEmptyCoreData, getInitialCoreData, isEmpty } from '../lib/liquidationCalculator'
  import LiquidationCalculatorCore from './LiquidationCalculatorCore.svelte'
  import { onMount, createEventDispatcher } from 'svelte'
  import LoneWalletConnectButton from './LoneWalletConnectButton.svelte'
  import { confirmWithDoNotShowAgain, formatCurrency, getAddressShortLabel } from '../lib/utils'
  import { quoteCoin } from '../stores/quoteCoin'
  import uri from 'uri-tag'
  import dialogs from '../stores/dialogs'
  import WalletDetails from './WalletDetails.svelte'
  import NotificationsButton from './NotificationsButton.svelte'
  import html from 'html-template-tag'
  import InfoTooltip from './InfoTooltip.svelte'

  const dispatch = createEventDispatcher()

  onMount(() => {
    dispatch('init')
  })

  $activeTabId = thisTabId // eslint-disable-line prefer-const
  $: isActiveTab = $activeTabId === thisTabId

  setTimeout(() => {
    $activeTabId = thisTabId // eslint-disable-line prefer-const
  }, Math.random() * 500) // This is to prevent collisions on simultaneous load

  let reloadCounter = 0
  function reload () {
    reloadCounter++
  }

  let headerDropdownOpen = false

  if (!$calculatorState.initialized) {
    $calculatorState.initialized = true
    if (!$activeWalletAddress) reset()
    reloadCounter = 0
  }

  function reset () {
    if ($activeWalletAddress) throw new Error('Cannot reset with active wallet address')

    $calculatorState.autoMarketRefresh = true

    for (const service of Object.keys(services)) {
      $calculatorState.services[service] = getInitialCoreData(service)
    }

    updateStateFromMarkets()

    reload()
  }

  async function resetWithConfirmation () {
    if (!await confirmWithDoNotShowAgain('resetCalculator', {
      title: 'Delete calculation',
      message: html`<div style="font-size: 200%; text-align: center; margin-top: -0.5em; margin-bottom: 0.5em;"><i class="fas fa-trash-alt"></i></div>Deleting will clear the calculator values and they cannot be restored.`,
      type: 'is-primary',
      confirmText: 'Continue'
    })) return
    await unlinkImportedWallet()
    reset()
  }

  function onServiceChange () {
    if (!$calculatorState.servicesLinked) reload()
  }

  // Without using the property store, this would result in an update of itself because $calculatorState would get dirty through updateStateFromMarkets!
  $: if (isActiveTab && $markets && autoMarketRefresh) {
    updateStateFromMarkets()
  }

  $: latestUpdateStateCookie = `${$activeWalletAddress}:${$activeWallet?.liveData?.lastUpdate}`

  let prevMode = $mode

  $: if ($mode !== prevMode) {
    if ($mode === 'readOnly') {
      restoreRoState(latestUpdateStateCookie)
    } else {
      saveRoState()
    }
    prevMode = $mode
  }

  $: if (isActiveTab && $activeWallet?.liveData && $calculatorState.updateStateCookie !== latestUpdateStateCookie) {
    updateStateFromLiveData($activeWallet.liveData, latestUpdateStateCookie)
  }

  $: if (isActiveTab && !$activeWalletAddress) {
    if (!$calculatorState.servicesLinked) {
      for (const [service] of Object.keys($calculatorState.services)) {
        if (service !== $selectedService) {
          $calculatorState.services[service] = getEmptyCoreData(service)
        }
      }
    }
    $calculatorState.servicesLinked = true
    $calculatorState.updateStateCookie = null
  }

  $: ethBalance = $activeWallet?.liveData?.balances?.find(b => b.symbol === 'ETH')?.units ?? 0

  $: recentActivity = [...$activeWallet?.history ?? []].sort((a, b) => b.timestamp - a.timestamp).slice(0, 3)

  const getServiceName = service => services[service]?.name ?? service

  if (location.hash.startsWith('#service=')) {
    const newService = location.hash.split('=')[1]
    if (services[newService]) {
      setTimeout(() => {
        onServiceChange(newService)
        window.history.pushState({}, document.title, window.location.pathname + window.location.search)

        setTimeout(() => updateLiveData(), 100)
      }, 200)
    }
  }

  async function copyAddress () {
    try {
      await navigator.clipboard.writeText($activeWallet.address)
      Toast.create({ message: 'Address copied to clipboard.', type: 'is-success' })
    } catch (e) {
      console.error('Clipboard writing failed', e)
      Toast.create({ message: 'Clipboard writing failed!', type: 'is-danger' })
    }
  }

  let tab = 'details'

  $: if (!$activeWallet) tab = 'details'
</script>

<style lang="scss">
  @import "bulma/sass/utilities/mixins.sass";

  .top-level {
    min-height: 30px;

    display: grid;
    // left, center, right
    // on mobile: left, right, next line: center
    // in left/right: align items to left/right as well
    grid-template-columns: 1fr auto 1fr;
    grid-template-areas: "left center right";
    align-items: start;
    gap: 0.5em;

    margin-bottom: 1em;

    @include touch {
      grid-template-columns: 1fr auto;
      grid-template-rows: auto auto;
      grid-template-areas: "left right" "center center";

      padding-left: 1em;
      padding-right: 1em;
    }

    > * {
      display: flex;
      align-items: center;
      gap: 0.5em;
      margin: 0;
    }

    .item-left {
      grid-area: left;
      justify-self: start;
      justify-content: flex-start;
      padding-top: 3px;
      min-height: 35px;
    }

    .item-center {
      grid-area: center;
      justify-self: center;
      justify-content: center;
    }

    .item-right {
      grid-area: right;
      justify-self: end;
      justify-content: flex-end;
      padding-top: 3px;
      min-height: 35px;
    }
  }

  .calculator-heading {
    position: relative;
    z-index: 2;

    :global(.icon) {
      transition: transform 0.2s ease-in-out;
    }
  }

  .has-dropdown-open .calculator-heading :global(.icon) {
    transform: rotate(180deg);
  }

  .calculator-heading-block {
    z-index: 1;
    display: inline-block;
    min-width: 220px;
    position: relative;
    padding-left: 0.5em;
    padding-right: 0.5em;

    .wallet-subtitle {
      text-transform: none;
      font-size: 0.5em;
      color: $grey;
      left: 0;
      width: 100%;
      font-weight: 400;
    }

    .calculator-heading-dropdown {
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      width: 100%;
      font-size: 1rem;
      font-family: $family-sans-serif;
      font-weight: 400;
      text-transform: none;

      padding: 0.5em;
      padding-top: 2.5em;
      background-color: $field-bg;
      border: 1px solid $border;
      border-radius: $radius;
      flex-direction: column;
      gap: 0.25em;

      .level {
        margin: 0.75rem 0;
      }

      .level-left, .level-right {
        align-self: start;
      }

      hr {
        background-color: $field-bg-hr;
        margin: 0.75rem 0;
      }

      small {
        color: $grey;
      }

      a {
        color: $text;

        &:hover {
          opacity: 0.75;
        }

        .level-item {
          line-height: 24px; // Same as icon
        }
      }

      .dropdown-tabs .dropdown-tab-container {
        display: flex;
        align-items: center;
        gap: 0.75em;
      }

      .dropdown-tabs .dropdown-tab.is-active {
        font-weight: bold;
        cursor: default;

        &:hover {
          opacity: inherit;
        }
      }

      .activity-tab {
        font-size: 0.75em;

        a {
          color: $link;
          text-decoration: underline;
        }
      }
    }
  }
</style>

<svelte:body on:click={e => { if (!e.target.closest('.calculator-heading-block')) headerDropdownOpen = false }} />

{#if isActiveTab}
  <main in:fade={{ duration: 300 }}>
    <div class="top-level">
      <h3 class="title is-3 has-text-centered is-uppercase item-center" in:fly={{ duration: 300, delay: 150, y: -20 }}>
        <div class="calculator-heading-block" class:has-dropdown-open={headerDropdownOpen}>
          {#if $mode !== 'readOnly'}
            {#if headerDropdownOpen}
              <div class="calculator-heading-dropdown has-text-left">
                {#if $activeWallet}
                  <div class="level dropdown-tabs">
                    <div class="level-left">
                      <div class="level-item">
                        <div class="dropdown-tab-container">
                          {#each [['details', 'Details'], ['activity', 'Activity']] as [id, label] (id)}
                            <a href={undefined} on:click={() => { tab = id }} class="dropdown-tab" class:is-active={tab === id}>{label}</a>
                          {/each}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                {/if}

                {#if tab === 'details'}
                  <div class="level">
                    <div class="level-left"><div class="level-item">Balance:</div></div>
                    <div class="level-right">
                      <div class="level-item">
                        <div>
                          <span>{formatCurrency(ethBalance, undefined, -6, '-', true)} ETH</span><br />
                          <small>{formatCurrency(ethBalance * $quoteCoin.ethPrice, $quoteCoin.symbol, undefined, '-', true)}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <a href={uri`https://etherscan.io/address/${$activeWallet.address}`} target="_blank" rel="noopener noreferrer" on:click={() => { headerDropdownOpen = false }}>
                    <div class="level is-mobile">
                      <div class="level-left"><div class="level-item">See on Etherscan</div></div>
                      <div class="level-right"><div class="level-item"><Icon icon="external-link" /></div></div>
                    </div>
                  </a>
                  <hr />
                  <a href={undefined} on:click={() => { copyAddress(); headerDropdownOpen = false }}>
                    <div class="level is-mobile">
                      <div class="level-left"><div class="level-item">Copy full address</div></div>
                      <div class="level-right"><div class="level-item"><Icon icon="clone" pack="far" /></div></div>
                    </div>
                  </a>
                  <hr />
                  <a href={undefined} on:click={() => { dialogs.open(WalletDetails, { address: $activeWallet.address }); headerDropdownOpen = false }}>
                    <div class="level is-mobile">
                      <div class="level-left"><div class="level-item">More information</div></div>
                      <div class="level-right"><div class="level-item"><Icon icon="ellipsis" /></div></div>
                    </div>
                  </a>
                {:else if tab === 'activity'}
                  <div class="activity-tab has-text-left">
                    {#if recentActivity.length}
                      {#each recentActivity as item (item.hash)}
                        <div class="level">
                          <div class="level-left">
                            <div class="level-item">
                              <div>
                                <span class="is-uppercase">{item.data?.type ?? 'unknown'}</span><br />
                                <span class="is-uppercase">{getServiceName(item.data?.service ?? 'unknown')}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="level">
                          <div class="level-left">
                            <div class="level-item">
                              <span><a href={uri`https://etherscan.io/tx/${item.hash}`} target="_blank" rel="noopener noreferrer" on:click={() => { headerDropdownOpen = false }}>{getAddressShortLabel(item.hash)}</a></span>
                            </div>
                          </div>
                          <div class="level-right">
                            <div class="level-item">
                              <span>{new Date(item.timestamp).toLocaleString('en-US')}</span>
                            </div>
                          </div>
                        </div>
                        <hr />
                      {/each}
                      <div class="has-text-centered">
                        <a href={undefined} on:click={() => { dialogs.open(WalletDetails, { address: $activeWallet.address, initialTabIndex: 2 }); headerDropdownOpen = false }}>All</a>
                      </div>
                    {:else}
                      No WEHODL activity yet.
                    {/if}
                  </div>
                {/if}
              </div>
            {/if}
            <a href={undefined} on:click={() => { headerDropdownOpen = !headerDropdownOpen }} class="calculator-heading black-link large-icon"><Icon icon="caret-down" /> {getWalletName($activeWallet)}</a>
          {:else}
            Calculator
            {#if $activeWallet}
              <div class="wallet-subtitle">Based on <Tooltip label="See on Etherscan" position="is-right"><a href={uri`https://etherscan.io/address/${$activeWalletAddress}`} target="_blank" rel="noopener noreferrer">{getWalletName($activeWallet)}</a></Tooltip></div>
            {/if}
          {/if}
        </div>
      </h3>

      <div class="item-left" in:fly={{ duration: 300, delay: 150, x: -20 }}>
        {#if $mode === 'readOnly'}
          <div class="info-tooltip-area">
            <Switch size="is-small" bind:checked={$calculatorState.autoMarketRefresh} />
            <span class="calc-label small has-text-left is-inline-block">
              Market
              <InfoTooltip>
                When this switch is enabled, market data (such as asset prices) is automatically refreshed. This can lead to a change of fiat prices displayed when the market price of an asset changes. You can disable this switch to prevent automatic updates and keep the calculation static.
              </InfoTooltip>
            </span>
          </div>
        {:else}
          <Tooltip position="is-right" label="Import Wallet Data to the Calculator">
            <Button size="is-small" outlined on:click={() => { clearRoState(); setImportedWallet($activeWallet.address) } } disabled={$updatingLiveData}><Icon size="is-small" icon="calculator" /></Button>
          </Tooltip>
        {/if}
      </div>

      <div class="item-right" in:fly={{ duration: 300, delay: 150, x: 20 }}>
        {#if $activeWalletAddress}
          {#if $mode !== 'readOnly'}
            <NotificationsButton tooltipPosition="is-left" />
          {/if}
          <Tooltip position="is-left" label="Sync Address">
            <Button size="is-small" outlined on:click={() => updateLiveData(true)} loading={$updatingLiveData}><Icon size="is-small" icon="sync" /></Button>
          </Tooltip>
          {#if $mode !== 'readOnly'}
            <Tooltip position="is-left" label="Disconnect Wallet">
              <Button size="is-small" outlined on:click={() => unlinkConnectedWallet()} disabled={$updatingLiveData}><Icon size="is-small" icon="power-off" /></Button>
            </Tooltip>
          {:else}
            <Tooltip position="is-left" label="Unlink Address">
              <Button size="is-small" outlined on:click={() => unlinkImportedWallet()} disabled={$updatingLiveData}><Icon size="is-small" icon="unlink" /></Button>
            </Tooltip>
          {/if}
        {/if}

        {#if $mode === 'readOnly' && (Object.values($calculatorState.services).some(s => !isEmpty(s)) || $activeWalletAddress)}
          <Tooltip position="is-left" label="Delete calculation">
            <Button size="is-small" outlined on:click={resetWithConfirmation}><Icon icon="trash-alt" /></Button>
          </Tooltip>
        {/if}
      </div>
    </div>

    <div class="is-hidden-tablet">
      <LoneWalletConnectButton />
    </div>

    <section class="calculator-core-content">
      {#key reloadCounter}
        <LiquidationCalculatorCore baseAnimationDelay={reloadCounter === 1 ? 0 : 150} bind:coreData={$currentCoreData} on:serviceChange={onServiceChange} />
      {/key}
    </section>
  </main>
{:else}
  <div class="has-text-centered notification is-info">
    <p class="m-3"><Icon icon="power-off" size="is-large" /></p>
    <h1 class="title">The calculator is currently in use in another tab.</h1>
    <h2 class="subtitle">
      <slot />
      The borrow/liquidation calculator can only be used in one tab at a time.
    </h2>
    <Button outlined inverted type="is-info" on:click={() => ($activeTabId = thisTabId)}>Use here</Button>
  </div>
{/if}
