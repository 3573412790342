'use strict'

import humanizeDuration from 'humanize-duration'
import { get } from 'svelte/store'
import EventEmitter from 'eventemitter3'
import { Dialog } from 'svelma-fixed'
import html from 'html-template-tag'

export function delay (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function escape (s, nl2br = false) {
  if (!s) return s
  const i = document.createElement('i')
  i.innerText = s
  return nl2br ? i.innerHTML.replace(/\n/g, '<br>') : i.innerHTML
}

export function removeUndefined (object) { // Note that this does mutate the object
  for (const [k, v] of Object.entries(object)) {
    if (v === undefined) delete object[k]
  }
  return object
}

const ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
export function generateRandomString (length) {
  let s = ''
  for (let i = 0; i < length; i++) {
    s += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length))
  }
  return s
}

export function formatPercentage (ratio, minDecimals = 0, maxDecimals = 2, nullText = '') {
  if (ratio == null || !Number.isFinite(ratio)) return nullText
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals
  }).format(ratio)
}

export function formatCurrency (amount, currency = undefined, decimals = 2, nullText = '', convertMillions = false) {
  if (amount == null || Number.isNaN(amount)) return nullText

  if (convertMillions && Math.abs(amount) >= 1e6 && Math.abs(amount) < Infinity) {
    if (Math.abs(amount) >= 1e12) {
      return 'Too Much ☹'
    } else {
      let suffix = 'm'
      let adjustedAmount = amount / 1e6
      if (Math.abs(amount) >= 1e9) {
        suffix = 'bn'
        adjustedAmount = amount / 1e9
      }
      return new Intl.NumberFormat('en-US', {
        style: currency ? 'currency' : 'decimal',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 3
      }).format(adjustedAmount) + suffix
    }
  }

  const isCrypto = ['BTC', 'ETH'].includes(currency)
  return new Intl.NumberFormat('en-US', {
    style: currency ? 'currency' : 'decimal',
    currency,
    ...decimals < 0
      ? {
          maximumSignificantDigits: -decimals
        }
      : {
          minimumFractionDigits: isCrypto ? 0 : decimals,
          maximumFractionDigits: isCrypto ? 4 : decimals
        }
  }).format(amount)
}

export function parseCurrency (value) {
  const clean = value.replace(/[^\d.∞-]/g, '')
  if (clean === '∞') return Infinity
  if (clean === '-∞') return -Infinity
  return Number(clean) || 0
}

export function parsePercentage (value) {
  return parseCurrency(value) / 100
}

export function hsl2rgb (h, s, l) {
  s /= 100
  l /= 100
  const C = (1 - Math.abs(2 * l - 1)) * s
  const hue = h / 60
  const X = C * (1 - Math.abs(hue % 2 - 1))
  let r = 0
  let g = 0
  let b = 0
  if (hue >= 0 && hue < 1) {
    r = C
    g = X
  } else if (hue >= 1 && hue < 2) {
    r = X
    g = C
  } else if (hue >= 2 && hue < 3) {
    g = C
    b = X
  } else if (hue >= 3 && hue < 4) {
    g = X
    b = C
  } else if (hue >= 4 && hue < 5) {
    r = X
    b = C
  } else {
    r = C
    b = X
  }
  const m = l - C / 2
  r += m
  g += m
  b += m
  r *= 255.0
  g *= 255.0
  b *= 255.0
  return [Math.round(r), Math.round(g), Math.round(b)]
}

// Wraps an event handler to trigger only on native events
export function onlyNative (handler) {
  return event => {
    if (event instanceof window.CustomEvent) return
    return handler(event)
  }
}

// Wraps an event handler to trigger only on custom events
// For example, to trigger on Svelma's custom input events that happen after bound value was already updated!
export function onlyCustom (handler) {
  return event => {
    if (!(event instanceof window.CustomEvent)) return
    return handler(event)
  }
}

export function getHumanizedDuration (duration) {
  if (duration < 10000) return 'a few seconds'
  if (duration < 60000) return 'less than a minute'
  return humanizeDuration(duration, { largest: 2, units: ['d', 'h', 'm'], round: true })
}

export function setupStoreGuard (store, guard) {
  const { set, update } = store

  store.set = value => {
    let prev
    // This has to be called anyway, unfortunately, otherwise `$store = value` will break in weird ways
    update(old => {
      prev = old
      return value
    })

    try {
      const finalValue = guard(value, prev)
      if (finalValue !== prev) set(finalValue)
    } catch (e) {
      set(prev)
      throw e
    }
  }

  store.update = updater => {
    store.set(updater(get(store)))
  }
}

export function makeStoreEventEmitter (store) {
  Object.setPrototypeOf(store, EventEmitter.prototype)
  EventEmitter.call(store)
}

export function autoSubscribe (onMount, eventEmitter, event, handler) {
  onMount(() => {
    eventEmitter.on(event, handler)
    return () => eventEmitter.off(event, handler)
  })
}

export function autoFocusInput (node) {
  node.querySelector('input').focus()
}

const escapeRegexRegex = /[-/\\^$*+?.()|[\]{}]/g
export function escapeRegex (string) {
  return string.replace(escapeRegexRegex, '\\$&')
}

export function getAddressShortLabel (address) {
  return `${address.slice(0, 5)}…${address.slice(-3)}`
}

export function getErrorMessage (err) {
  if (err.message.includes('See {error}')) return err.error.message
  const reasonMatch = err.message.match(/\breason="(.*?)"/)
  if (reasonMatch) return reasonMatch[1]
  return err.message.replace(/ \[See: https:\/\/links.ethers.org\/$/, '')
}

export function formatError (error) {
  if (!error) return undefined
  if (typeof error === 'string') return error
  if (error.message) return getErrorMessage(error)
  if (error.stack && String(error) !== '[object Object]') return String(error)
  return JSON.stringify(error)
}

window.dnsaCheckboxes = {}

export async function confirmWithDoNotShowAgain (key, options = {}) {
  const lsKey = 'wehodlDnsa:' + key
  window.dnsaCheckboxes[lsKey] = false
  const doNotShowAgain = window.localStorage[lsKey] === '1'
  if (doNotShowAgain) return true

  const onChange = (event, lsKey) => {
    window.dnsaCheckboxes[lsKey] = event.target.checked
  }

  const result = await Dialog.confirm({
    ...options,
    message: options.message + html`<br><br><label><input type="checkbox" class="mr-1" name="doNotShowAgain" onchange="(${onChange})(event, ${JSON.stringify(lsKey)})"> Don't show this message again.</label>`
  })

  if (result && window.dnsaCheckboxes[lsKey]) {
    window.localStorage[lsKey] = '1'
  }

  return result
}
