<svelte:options accessors />

<script>
  import { createEventDispatcher, tick } from 'svelte'
  import ModalDialog from './ModalDialog.svelte'
  import { Button, Input } from 'svelma-fixed'
  import { quoteCoin } from '../stores/quoteCoin'
  import { activeWallet } from '../stores/walletManager'
  import { getDispSymbol } from '../stores/markets'
  import calculatorState from '../stores/calculatorState'
  import { confirmWithDoNotShowAgain, formatCurrency, onlyCustom, parseCurrency } from '../lib/utils'
  import html from 'html-template-tag'

  export let title = 'Update Asset'
  export let service
  export let type // collateral/borrow
  export let augmentedAsset
  export let allowZero = false
  export let useUnits = false
  export let empty = false
  export let hasQuantity = false
  export let hasPrice = false
  export let disabled = false
  export let onSubmit
  export let dispSymbolOverride

  export let quoteCoinSymbol = $quoteCoin.symbol
  export let activeWalletAddress = $activeWallet?.address

  const quoteCoinEthPrice = $calculatorState.quoteCoinPrices[$quoteCoin.symbol] ?? $quoteCoin.ethPrice
  export let originalQuotePrice = augmentedAsset.price * quoteCoinEthPrice

  export let units = empty ? null : augmentedAsset.units
  export let quotePrice = augmentedAsset.price * quoteCoinEthPrice
  export let priceChanged = quotePrice !== originalQuotePrice

  export function setUnits (value) {
    units = value
    formatEditValue('units', units, true)
    onUnitsChange(true)
  }

  const dispatch = createEventDispatcher()
  $: if (quoteCoinSymbol !== $quoteCoin.symbol) dispatch('close')
  $: if (activeWalletAddress !== $activeWallet?.address) dispatch('close')

  $: disp = getDispSymbol(augmentedAsset.symbol, service, true)

  $: dispSymbolWithoutOverride = disp.dispSymbol ?? augmentedAsset.symbol
  $: dispSymbol = dispSymbolOverride ?? dispSymbolWithoutOverride

  $: label = {
    collateral: 'Collateral',
    borrow: 'Borrow'
  }[type] ?? ''

  export let formEl

  const editValues = {
    units: '',
    quoteValue: '',
    quotePrice: ''
  }

  tick().then(() => {
    formatEditValue('quotePrice', quotePrice, true)
    if (units != null) {
      formatEditValue('units', units, true)
      onUnitsChange(true)
    }
  })

  async function submit () {
    if (onSubmit) return onSubmit()

    if (!units && !await confirmWithDoNotShowAgain('removeAssetWithZeroUnits', {
      title: 'Remove asset',
      message: html`Setting an asset's quantity to zero will remove it from the calculation. Are you sure you want to do this?`,
      type: 'is-primary',
      confirmText: 'Continue'
    })) return

    dispatch('close', { units, quotePrice: priceChanged ? quotePrice : null })
  }

  function parseEditValue (key, value = editValues[key], useDefault = true) {
    return ({
      units: () => Math.max(0, parseCurrency(value)),
      quoteValue: () => Math.max(0, parseCurrency(value, $quoteCoin.symbol)),
      quotePrice: () => Math.max(0, parseCurrency(value, $quoteCoin.symbol))
    })[key]()
  }

  function reformatEditValue (key) {
    if (key === 'quotePrice') {
      if (!parseEditValue(key)) {
        formatEditValue(key, originalQuotePrice, true)
        onQuotePriceChange(true)
        priceChanged = false
      }
    }

    formatEditValue(key, parseEditValue(key), true)
  }

  function formatEditValue (key, value, force = false) {
    const formatted = ({
      units: () => value ? formatCurrency(value, undefined, -10) + ' ' + dispSymbol : '',
      quoteValue: () => value ? formatCurrency(value, $quoteCoin.symbol) : '',
      quotePrice: () => formatCurrency(value, $quoteCoin.symbol)
    })[key]()

    if (force || editValues[key] === undefined || parseEditValue(key, formatted) !== parseEditValue(key)) {
      editValues[key] = formatted
      tick().then(() => { editValues[key] = formatted }) // Weird bug: Input value doesn't update if it's not set twice in some conditions? (Test with changing units...)
    }
  }

  function onUnitsChange (force = false) {
    if (!force) units = parseEditValue('units')

    formatEditValue('quoteValue', units * quotePrice, force)
    if (!force) dispatch('change', units)
  }

  function onQuoteValueChange (force = false) {
    units = parseEditValue('quoteValue') / quotePrice

    formatEditValue('units', units, force)
    if (!force) dispatch('change', units)
  }

  function onQuotePriceChange (force = false) {
    quotePrice = parseEditValue('quotePrice', undefined)

    if (!quotePrice) {
      quotePrice = originalQuotePrice
      priceChanged = false
    } else {
      priceChanged = true
    }

    onUnitsChange(force)
  }

  async function toggleUseUnits () {
    useUnits = !useUnits
    await tick()
    // formEl.querySelector('input')?.focus() // This will focus to the end of the input field, and if the value is too long, it is problematic... so we don't focus atm
  }

  function resetPrice () {
    quotePrice = originalQuotePrice
    formatEditValue('quotePrice', quotePrice, true)
    onQuotePriceChange(true)
    priceChanged = false
  }
</script>

<style lang="scss">
  .image {
    width: 74px;
    height: 74px;
    padding: 11px;
    border-radius: 50%;
    background-color: $field-bg;
    border: 1px solid $border;
    display: inline-block;

    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
  }

  .submit-row :global(.button) {
    font-size: 175%;
    padding: 0.5rem 2rem;
    line-height: 1;
    height: auto;
    font-weight: 500;
  }

  .label {
    margin-bottom: 0 !important;
  }

  .field {
    position: relative;
  }

  .field.quantity :global(input) {
    text-align: center;
    font-size: 300%;
    font-weight: bold;
    padding-top: 2rem;
    padding-bottom: 2rem;
    max-width: 400px;
  }

  .field.quantity aside {
    margin-top: -1.5em;
    display: block;
    position: relative;
    left: 0;
    width: 100%;
    bottom: 0.5em;
    text-align: center;
    max-width: 400px;
    height: 1.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 15px;

    a {
      color: #c2c2c2; // Placeholder color

      &:hover {
        opacity: 0.75;
      }
    }
  }

  .field.price :global(input) {
    text-align: center;
    font-size: 175%;
    font-weight: 500;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    height: auto;
    max-width: 400px;
  }

  .field :global(input) {
    &:active, &:focus {
      // Reduce size of box-shadow a bit
      box-shadow: rgba(102, 151, 229, 0.25) 0px 0px 0px 2px;
    }
  }
</style>

<ModalDialog large --min-width="none" --max-width="442px" {title} on:close closeable on:mount on:introend={() => formEl.querySelector('input[type="text"]')?.focus()}>
  <svelte:fragment slot="title">
    <slot name="title" />
    <div class="subtitle is-7 is-uppercase has-text-grey">{label}</div>
  </svelte:fragment>

  <section class="asset-profile has-text-centered mb-5">
    <figure class="image mb-2"><img src="{augmentedAsset.coin.iconUrl}" alt="{augmentedAsset.symbol}"></figure>
    <h5 class="title is-5 m-0">{dispSymbolWithoutOverride}</h5>
    <p class="subtitle is-7 m-0">{disp.name ?? augmentedAsset.coin.name}</p>
  </section>

  <slot name="beforeForm" />

  <form novalidate bind:this={formEl} on:submit|preventDefault={submit}>
    <fieldset {disabled}>
      <slot name="beforeFields" />

      {#if type === 'collateral' && augmentedAsset.serviceData.collateralForcedEnableState === false}
        <div class="notification is-info">Note: This asset can never be enabled as collateral. It will be used only to earn interest as a non-collateral supply asset.</div>
      {/if}

      {#if hasQuantity}
        <div class="field quantity">
          <div class="label level is-mobile">
            <div class="level-left"><div class="level-item">Quantity</div></div>
            <div class="level-right"><div class="level-item"><span class="mini-button"><Button size="is-small" type="is-primary" on:click={toggleUseUnits}>{useUnits ? $quoteCoin.symbol : dispSymbol}</Button></span></div></div>
          </div>
          {#if useUnits}
            <Input placeholder="{formatCurrency(0, undefined, -10)} {dispSymbol}" bind:value={editValues.units} on:input={onlyCustom(() => onUnitsChange())} on:change={() => reformatEditValue('units')} inputmode="decimal" pattern="[0-9.]\d" novalidate />
            <aside><a href={undefined} on:click={toggleUseUnits}>{formatCurrency((units * quotePrice) || 0, $quoteCoin.symbol)}</a></aside>
          {:else}
            <Input placeholder={formatCurrency(0, $quoteCoin.symbol)} bind:value={editValues.quoteValue} on:input={onlyCustom(() => onQuoteValueChange())} on:change={() => reformatEditValue('quoteValue')} inputmode="decimal" pattern="[0-9.]\d" novalidate />
            <aside><a href={undefined} on:click={toggleUseUnits}>{formatCurrency(units || 0, undefined, -10)} {dispSymbol}</a></aside>
          {/if}
          <slot name="quantityFieldAfterContent" />
        </div>
      {/if}

      {#if hasPrice}
        <div class="field price">
          <div class="label level is-mobile">
            <div class="level-left"><div class="level-item">Price</div></div>
            <div class="level-right">
              {#if priceChanged}
                <div class="level-item"><span class="mini-button"><Button size="is-small" type="is-primary" on:click={resetPrice}>Market Price</Button></span></div>
              {/if}
            </div>
          </div>
          <Input placeholder="{formatCurrency(originalQuotePrice, $quoteCoin.symbol)}" bind:value={editValues.quotePrice} on:input={onlyCustom(() => onQuotePriceChange())}  on:change={() => reformatEditValue('quotePrice')} inputmode="decimal" pattern="[0-9.]\d" novalidate />
        </div>
      {/if}

      <slot name="afterFields" />

      <p class="has-text-centered is-uppercase submit-row mt-4">
        <slot name="button">
          <Button type="is-primary" nativeType="submit" disabled={(!units && !allowZero) || quotePrice === 0}>Save</Button>
        </slot>
      </p>
    </fieldset>
  </form>
</ModalDialog>
