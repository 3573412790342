<script>
  import DetailsTable from './DetailsTable.svelte'
  import { Icon, Input } from 'svelma-fixed'
  import pluralize from 'pluralize'
  import { escapeRegex, getAddressShortLabel } from '../lib/utils'
  import uri from 'uri-tag'
  import { updateTransaction } from '../stores/txManager'

  export let wallet // only for the Ctrl+Shift+DblClick debug feature
  export let search = ''
  export let transactions = []

  $: searchRegex = new RegExp(`\\b${escapeRegex(search.trim())}`, 'i')

  const filterTransactions = txs => txs.filter(tx => !search || tx.description?.match(searchRegex) || tx.hash?.match(searchRegex))
</script>

<DetailsTable>
  <div class="level">
    <div class="level-left">
      <Input bind:value={search} placeholder="Search transaction" type="search" size="is-small" expanded />
    </div>
    <div class="level-right">
      <strong>{pluralize('WEHODL transaction', transactions.length, true)}</strong>
    </div>
  </div>

  {#each filterTransactions(transactions) as tx (tx.hash)}
    <div class="level is-align-items-flex-start">
      <div class="level-left">
        <div class="level-item is-justify-content-flex-start" on:dblclick={e => { if (e.shiftKey && e.ctrlKey) updateTransaction(wallet.address, tx.hash, { status: 'pending' }) }}>
          <Icon icon={tx.icon ?? 'scroll'} />
          <div class="ml-2">
            {tx.description ?? 'Unknown transaction'}
            <br />
            <small>{new Date(tx.timestamp).toLocaleString()}</small>
          </div>
          <br />
        </div>
      </div>
      <div class="level-right has-text-right-tablet">
        <div class="level-item is-justify-content-flex-start">
          <div>
            {#if tx.status === 'success'}
              <span class="tag is-success">Confirmed</span>
            {:else if tx.status === 'failed'}
              <span class="tag is-danger">Failed</span>
            {:else if tx.status === 'pending'}
              <Icon icon="spinner" customClass="fa-pulse" /> <span class="tag is-warning">Pending</span>
            {:else if tx.status === 'dropped'}
              <span class="tag is-dark">Dropped</span>
            {:else}
              <span class="tag is-dark">Unknown</span>
            {/if}
            <br />
            <small><a href={uri`https://etherscan.io/tx/${tx.hash}`} target="_blank" rel="noopener noreferrer">{getAddressShortLabel(tx.hash)}</a></small>
          </div>
        </div>
      </div>
    </div>
  {:else}
    {#if search}
      <div>There are no transactions matching "{search}".</div>
    {:else}
      <div>No transactions found.</div>
    {/if}
  {/each}
</DetailsTable>
