<script>
  import { Icon } from 'svelma-fixed'
  import { createEventDispatcher } from 'svelte'
  import { fly, scale, slide } from 'svelte/transition'
  import { calculateFullState } from '../lib/liquidationCalculator'
  import services from '../lib/services'
  import { formatPercentage } from '../lib/utils'
  import { calculatorState, switchService } from '../stores/calculatorState'
  import markets, { augmentCoinForService } from '../stores/markets'
  import { quoteCoin } from '../stores/quoteCoin'
  import Circle from './Circle.svelte'
  import InfoTooltip from './InfoTooltip.svelte'
  import LiquidationCalculatorAssetList from './LiquidationCalculatorAssetList.svelte'
  import ValueDisplay from './ValueDisplay.svelte'
  import { activeWalletAddress } from '../stores/walletManager'
  import AutoUpdate from './AutoUpdate.svelte'
  import humanizeDuration from 'humanize-duration'
  import DropdownDialog from './DropdownDialog.svelte'
  import dialogs from '../stores/dialogs'
  import { devOptions } from '../stores/devOptions'

  const dispatch = createEventDispatcher()

  export let coreData
  if (!coreData) throw new Error('coreData must be set')

  export let baseAnimationDelay = 0

  let collateralAssetList
  let borrowAssetList

  $: fullState = calculateFullState($calculatorState, $quoteCoin, $markets, coreData)

  $: possibleNewCoinItems = Object.values($markets.coins)
    .filter(coin => !coin.hidden)
    .map(coin => augmentCoinForService(coin, fullState))
    .map(coin => ({ ...coin, selectable: !coin.inUse }))

  async function serviceDropdownClick () {
    const newService = await dialogs.open(DropdownDialog, { value: coreData.service, items: Object.values(services) })
    if (newService) {
      switchService(newService)
      dispatch('serviceChange', newService)
    }
  }
</script>

<style lang="scss">
  @import "bulma/sass/utilities/mixins.sass";

  .liquidation-circle {
    font-size: 1.25rem;

    :global(.liquidated) {
      background-color: transparent;
      color: white;
    }

    a.circle-link {
      color: white !important;
      font-size: 150%;
      font-weight: bold;

      &:hover {
        opacity: 0.9;
      }

      :global(.icon) {
        font-size: inherit;
        font-weight: normal;
      }
    }
  }

  .service-disabled {
    filter: blur(6px);
    pointer-events: none;
    opacity: 0.6;
  }

  #service-disabled-overlay {
    filter: none;
    opacity: 1;
    pointer-events: auto;
    z-index: 2;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    padding: 50px;
    text-align: center;
    font-size: 20px;
    max-height: 300px;
    overflow: hidden;

    @include mobile {
      padding: 20px;
      font-size: 16px;
    }
  }

  .service-dropdown {
    font-weight: normal;
  }
</style>

<main>
  {#if services[coreData.service]?.disabled}
    <div id="service-disabled-overlay" in:fly={{ y: 20, duration: 250, delay: baseAnimationDelay }}>
      <div class="mb-4"><Icon icon="wrench" size="is-large" /></div>
      We are currently working on improving {services[coreData.service].name} in our calculator and it will be available again soon. Please select another service in the calculator or use the {services[coreData.service].name} website directly in the meantime. We apologize for the inconvenience.
    </div>
  {/if}

  <div class="liquidation-circle has-text-white has-text-centered mb-5" class:service-disabled={services[coreData.service]?.disabled} in:scale={{ duration: 450, delay: 250, start: 0.7, opacity: 0 }}>
    <Circle colorEasing --size="300px" --thickness="20px" --padding="10px" --border-color={fullState.calcValues.circleOuterColor} --bg-color={fullState.calcValues.circleInnerColor}>
      {#if !fullState.calcValues.collateralValue}
        <span class="calc-label strong is-uppercase">No funds</span>
        <p style:font-size="0.8em">
          {#if !fullState.assets.collateral.length}
            <a class="circle-link is-uppercase" href={undefined} on:click={() => collateralAssetList.add()}><Icon icon="plus-square" pack="far" /> Add asset</a>
          {:else}
            Add/enable some collateral below to get started!
          {/if}
        </p>
      {:else if !fullState.assets.borrow.length}
        <span class="calc-label strong is-uppercase">No loan</span>
        <p style:font-size="0.8em">
          <a class="circle-link is-uppercase" href={undefined} on:click={() => borrowAssetList.add()}><Icon icon="plus-square" pack="far" /> Borrow asset</a>
        </p>
      {:else}
        <div class="info-tooltip-area mb-5">
          <span class="calc-label strong m-0">
            Safety Margin
            <InfoTooltip>
              <p>The Safety Margin represents the distance from the current market value in percentages %.</p>
              <p>Keep a safe margin from potential market changes.</p>
              <p>The circle's color represents the level of risk:</p>
              <ul>
                <li>- Green : Low risk</li>
                <li>- Orange : Medium risk</li>
                <li>- Red : High risk</li>
              </ul>
            </InfoTooltip>
          </span>
          <fieldset style:line-height="1">
            <ValueDisplay --font-size="320%" danger={fullState.calcValues.marketDropRatio < 0} value={formatPercentage(Math.max(0, fullState.calcValues.marketDropRatio), 2, 2)} />
          </fieldset>
        </div>
      {/if}

      {#if fullState.calcValues.collateralValue}
        <div style:position="relative" style:width="100%">
          <div style:position="absolute" style:width="100%" class="info-tooltip-area">
            <span class="calc-label strong m-0" style:font-size="75%">
              {#if $activeWalletAddress && coreData.stats?.netApy != null}
                Net APY
              {:else}
                Est. APY
              {/if}
              <InfoTooltip>
                <p>Annual yield as combined value from interest earned on supplied assets as well as paid on borrowed assets, plus incentives/rewards.</p>
                {#if coreData.service === 'aaveV2'}
                  <p>Note that Aave V2's own website does the calculation a bit differently, but the value shown here is consistent with other DeFi services.</p>
                {/if}
              </InfoTooltip>
            </span>
            <fieldset style:line-height="1">
              {#if $devOptions.debugOptions.comparisonMultiApy}
                <ValueDisplay --font-size="100%" value={formatPercentage(($activeWalletAddress && coreData.stats?.netApy != null) ? coreData.stats.netApy : fullState.calcValues.estimatedNetApy.variable, 2, 2, 'N/A')} />|
                <ValueDisplay --font-size="100%" value={formatPercentage((($activeWalletAddress && coreData.stats?.netApyEth != null) ? coreData.stats.netApyEth : fullState.calcValues.estimatedNetApy.variableEth) / (fullState.assets.collateral.reduce((acc, asset) => acc + asset.ethValue ?? 0, 0) - fullState.assets.borrow.reduce((acc, asset) => acc + asset.ethValue ?? 0, 0)), 2, 2, 'N/A')} />|
                <ValueDisplay --font-size="100%" value={formatPercentage((($activeWalletAddress && coreData.stats?.netApyEth != null) ? coreData.stats.netApyEth : fullState.calcValues.estimatedNetApy.variableEth) / fullState.assets.borrow.reduce((acc, asset) => acc + asset.ethValue ?? 0, 0), 2, 2, 'N/A')} />
              {:else}
                <ValueDisplay --font-size="100%" value={formatPercentage(($activeWalletAddress && coreData.stats?.netApy != null) ? coreData.stats.netApy : fullState.calcValues.estimatedNetApy.variable, 2, 2, 'N/A')} />
              {/if}
            </fieldset>
          </div>
        </div>
      {/if}
    </Circle>
  </div>

  <h3 class="service-dropdown title is-3 m-0 has-text-centered is-uppercase" in:fly={{ duration: 300, delay: baseAnimationDelay + 350, y: 20 }}>
    <a href={undefined} class="black-link large-icon" on:click={serviceDropdownClick}><Icon icon="caret-down" /> {services[coreData.service].name}</a>
  </h3>

  {#if $activeWalletAddress && coreData.stats?.firstBorrowTime}
    <div class="has-text-centered" in:fly={{ duration: 300, delay: baseAnimationDelay + 375, y: 20 }} out:slide|local>
      <span style:display="contents" class="info-tooltip-area">
        <span class="has-text-grey">
          <AutoUpdate always><span style:color="inherit" title={new Date(coreData.stats.firstBorrowTime).toLocaleString()}>{humanizeDuration(Date.now() - new Date(coreData.stats.firstBorrowTime), { largest: 1, units: ['y', 'mo', 'w', 'd', 'h', 'm', 's'], round: true })} ago</span></AutoUpdate>
        </span>
        <InfoTooltip>
          <p>The time when the first borrow was made on {services[coreData.service].name}.</p>
        </InfoTooltip>
      </span>
    </div>
  {/if}

  <div class="assets-area p-4">
    <div class="columns is-tablet">
      <div class="column is-half-tablet" in:fly={{ duration: 300, delay: baseAnimationDelay + 450, y: 35 }}>
        <LiquidationCalculatorAssetList bind:this={collateralAssetList} service={coreData.service} type="collateral" augmentedAssets={fullState.assets.collateral} bind:assets={coreData.assets.collateral} coinItems={possibleNewCoinItems.filter(coin => coin.services[coreData.service]?.collateralActive && !coin.services[coreData.service]?.removeOnly)} {fullState} />
      </div>
      <div class="column is-half-tablet" in:fly={{ duration: 300, delay: baseAnimationDelay + 550, y: 35 }}>
        <LiquidationCalculatorAssetList bind:this={borrowAssetList} service={coreData.service} type="borrow" augmentedAssets={fullState.assets.borrow} bind:assets={coreData.assets.borrow} coinItems={possibleNewCoinItems.filter(coin => coin.services[coreData.service]?.borrowActive && !coin.services[coreData.service]?.removeOnly)} borrowLimitReached={fullState.calcValues.borrowLimit < fullState.calcValues.borrowValue} {fullState} />
      </div>
    </div>
  </div>
</main>
