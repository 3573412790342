import { writable } from 'svelte/store'
import { apiCall } from '../lib/api'
import { ga } from '@beyonk/svelte-google-analytics'

const markets = writable(null)

markets.initialized = false

markets.lastUpdate = 0
markets.enableAutoUpdate = true
markets.blockUntil = 0

let lastUpdateId = 0

markets.update = async function () {
  const data = await apiCall('GET', '/api/markets')

  const thisUpdateId = ++lastUpdateId
  while (markets.blockUntil > Date.now()) {
    await new Promise(resolve => setTimeout(resolve, 500))
    if (thisUpdateId !== lastUpdateId) return
  }

  markets.set(data)
  markets.initialized = true
  markets.lastUpdate = Date.now()

  ga.addEvent('markets_updated', {})
}

let intervalId
markets.initAutoUpdate = function () {
  if (intervalId) clearInterval(intervalId)
  intervalId = setInterval(() => {
    if (!markets.enableAutoUpdate) return
    markets.update().catch(e => { console.warn('BG market update error', e) })
  }, window.appVariables.coinInfoUpdateInterval)
}

export function getDispSymbol (symbol, service, withName = false) {
  let result = {}
  if (symbol === 'WETH' && ['aaveV2', 'compoundV3_USDC'].includes(service)) {
    result = {
      dispSymbol: '(W)ETH',
      name: '(Wrapped) Ether'
    }
  }
  return withName ? result : (result.dispSymbol ?? symbol)
}

export function augmentCoinForService (coin, fullState) {
  if (!coin) return coin

  const result = {
    ...coin,
    inUse: [...fullState.assets.collateral, ...fullState.assets.borrow].some(asset => asset.symbol === coin.symbol),
    removeOnly: coin.services[fullState.service]?.removeOnly,
    ...getDispSymbol(coin.symbol, fullState.service, true)
  }

  return result
}

export const DEFAULT_COIN_ICON = '/images/coinIcons/default.png'

export default markets
