<script>
  import { createEventDispatcher, tick } from 'svelte'
  import ModalDialog from './ModalDialog.svelte'
  import { Input } from 'svelma-fixed'
  import { quoteCoin } from '../stores/quoteCoin'
  import { activeWallet } from '../stores/walletManager'
  import calculatorState from '../stores/calculatorState'
  import { calculateFullState } from '../lib/liquidationCalculator'
  import markets from '../stores/markets'
  import { fly } from 'svelte/transition'
  import { flip } from 'svelte/animate'
  import AssetCard from './AssetCard.svelte'
  import { escapeRegex } from '../lib/utils'

  export let service
  export let type // collateral/borrow
  export let coinItems = []
  export let title = 'Add Asset'
  export let onlyWithBalance = false

  export let quoteCoinSymbol = $quoteCoin.symbol
  export let activeWalletAddress = $activeWallet?.address

  let search = ''

  const quoteCoinEthPrice = $calculatorState.quoteCoinPrices[$quoteCoin.symbol] ?? $quoteCoin.ethPrice

  const dispatch = createEventDispatcher()
  $: if (quoteCoinSymbol !== $quoteCoin.symbol) dispatch('close')
  $: if (activeWalletAddress !== $activeWallet?.address) dispatch('close')

  $: label = {
    collateral: 'Collateral',
    borrow: 'Borrow'
  }[type] ?? ''

  function getAugmentedAssets (service, type, $calculatorState, $quoteCoin, $markets, $activeWallet, coinItems) {
    const getBalance = (symbol) => {
      const asset = $activeWallet?.liveData?.balances.find(a => a.symbol === symbol)
      return asset?.units ?? 0
    }

    const fakeCoreData = {
      service,
      assets: {
        collateral: [],
        borrow: [],
        [type]: coinItems.map(coin => ({ symbol: coin.symbol, units: getBalance(coin.symbol), enabled: true }))
      },
      stats: {},
      dirty: false,
      notificationsEnabled: false
    }

    const fullState = calculateFullState($calculatorState, $quoteCoin, $markets, fakeCoreData)
    return fullState.assets[type]
  }

  $: augmentedAssets = getAugmentedAssets(service, type, $calculatorState, $quoteCoin, $markets, $activeWallet, coinItems)

  let formEl

  function filter (augmentedAssets, search) {
    // If symbol or any word in name starts with <search>, show it
    // Note: must escape regex, use \b
    const regex = new RegExp(`\\b${escapeRegex(search)}`, 'i')
    return augmentedAssets.filter(augmentedAsset => augmentedAsset.symbol.toLowerCase().startsWith(search) || augmentedAsset.symbol.replace(/\W/g, '').toLowerCase().startsWith(search) || regex.test(augmentedAsset.coin?.name ?? ''))
  }

  function submit () {
    const augmentedAsset = filter(augmentedAssets, search)[0]
    if (augmentedAsset) dispatch('close', augmentedAsset)
  }

  // Auto-submit the one and only item!
  $: if (augmentedAssets.length === 1) tick().then(() => dispatch('close', augmentedAssets[0]))
</script>

<style lang="scss">
  .asset-div {
    max-width: 360px;

    &:last-child :global(.card) {
      margin-bottom: 0 !important;
    }
  }
</style>

<ModalDialog --max-height="600px" {title} on:close closeable on:introend={() => formEl.querySelector('input')?.focus()}>
  <div slot="title" class="subtitle is-7 is-uppercase has-text-grey">{label}</div>

  <form on:submit|preventDefault={submit} bind:this={formEl}>
    <Input placeholder="Search an asset..." bind:value={search} expanded />
  </form>

  {#each filter(augmentedAssets, search) as augmentedAsset (augmentedAsset.symbol)}
    {@const units = augmentedAsset.symbol === 'WETH' && ['aaveV2', 'compoundV3_USDC'].includes(service) ? augmentedAsset.units + ($activeWallet?.liveData?.balances.find(a => a.symbol === 'ETH')?.units ?? 0) : undefined}
    <div
      class="asset-div"
      transition:fly|local={{ duration: 200, y: -10 }}
      animate:flip={{ duration: 350 }}
    >
      <AssetCard
        {augmentedAsset}
        {service}
        {type}
        {quoteCoinEthPrice}
        showAmounts={!!$activeWallet}
        noFunds={onlyWithBalance && (units ?? augmentedAsset.units) <= 0}
        {units}
        on:click={() => dispatch('close', augmentedAsset)}
      />
    </div>
  {:else}
    <p>No matching assets available!</p>
  {/each}
</ModalDialog>
